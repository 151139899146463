import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { Store } from '@ngrx/store';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';
import { CartParams } from '@store/modals';
import { Location } from '@angular/common';
import { LocalStorageService } from '@services/local-storage-service';
import { AppConfig } from '../app.config';

@Component({ selector: 'product-cart', templateUrl: './cart.html', styleUrls: ['./cart.scss'] })
export class ProductCartComponent implements OnInit {
  @ViewChild('billDetailsContainer', { static: false }) billDetailsContainer: ElementRef<HTMLElement>;
  user: any;
  cart: any;
  cartItems: any = [];
  totalProducts: number;
  amountRemaining: number;
  aovExp: boolean = false;
  paramsAdd: boolean = false;
  noProductsInCart: boolean = false;
  isLoading: boolean = true;
  maxPrice: any;
  experiments: any[];
  minimumCash: any;
  cashBalance: any;
  tempCashBalance: any;
  showCashBalance: boolean = false;
  lastVisitedURL: any;
  freeProduct: any;
  rxLimit: number = 3;
  nonRxLimit: number = 5;
  noFreeProduct: boolean = false;
  showFreeProd: boolean;
  removeFreeGift: boolean;
  productsInCart: { [key: string]: number } = {};
  productsQuantity: { [key: string]: any } = {};
  productsInParams: { [key: string]: number } = {};
  routesList: any = [];
  discontinuedList: any = [];
  newProduct: any;
  oldProduct: any;
  popUp: any = {};
  isDiscontinued: boolean = false;
  totalMRP: number = 0;
  totalSP: number = 0;
  discount: number = 0;
  startTimer: number;
  newCartUI: boolean = false;
  showSavedText: boolean = false;
  showViewDetailedButtonContainer: boolean = true;
  freeProductsArray: any[] = [];
  freeProductBannerImageForAov: string;
  aovBannerPercentageDone: number = 0;

  constructor(
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private router: Router,
    private eventLogger: EventLoggerService,
    private appConfig: AppConfig,
    private windowRef: WindowRefService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private store: Store,
    private location: Location,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.startTimer = new Date().getTime();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'cart' }));
    this.store.select(fromSelectors.selectCartProducts).subscribe((products: any): any => this.productsInCart = { ...products });
    this.user = this.conn.getActingUser();
    this.experiments = await this.conn.findUserActiveExperiments();
    await this.getExperiments();
    this.minimumCash = this.appConfig.Shared.CART_PAGE_CASH_VISIBILITY_MIN_CASH;
    this.cashBalance = await this.conn.fetchCashBalance();
    this.tempCashBalance = this.cashBalance;
    if (this.cashBalance >= this.minimumCash) {
      this.showCashBalance = true;
    }
    await this.getCart();
    await this.modifyCartByParams();
    this.routesList = this.windowRef.nativeWindow.historyStates;
    this.noFreeProduct = this.localStorage.getValue('freeProduct');
    if (this.noFreeProduct) this.removeFreeGift = true;
    await this.getCart();
    if (this.routesList.length > 2) {
      this.lastVisitedURL = this.routesList[this.routesList.length - 2];
      if (this.lastVisitedURL.url.includes('user/order/reorder')) {
        const routesListTemp = [];
        routesListTemp[0] = { url: 'user?tab=home&init=true' };
        routesListTemp[1] = { url: this.lastVisitedURL.url };
        routesListTemp[2] = { url: 'cart' };
        this.windowRef.nativeWindow.historyStates = routesListTemp;
      }
    }
    this.getProductQuantity();
    this.fetchDiscount();
    this.isLoading = false;

    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('cart_page_loading_time', { timeInMilliSec: time, userId: this.user.id });
    this.changeDetectionRef.detectChanges();
    this.setupIntersectionObserver();
  }

  async getExperiments(): Promise<void> {
    this.experiments.forEach(async (experiment: any): Promise<void> => {
      if (experiment.key === 'aov_gamification' && this.user?.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) {
        this.aovExp = true;
        this.maxPrice = experiment.variant;
        const product = await this.conn.findCatalogWithKey([this.maxPrice.productID]);
        [this.freeProduct] = JSON.parse(JSON.stringify(product));
        if (this.freeProduct?.rebrandedImageWithoutBackground?.length) {
          this.freeProductBannerImageForAov = this.freeProduct.rebrandedImageWithoutBackground[0];
        }
      } if (experiment.key === 'new_cart_redesign') {
        this.newCartUI = true;
        this.showSavedText = !!experiment?.variant?.showSavedText;
      }
    });
  }

  setupIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px 0px -100px 0px',
      threshold: 1,
    };

    const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]): void => {
      this.onIntersection(entry.isIntersecting);
    }, options);
    if (this.billDetailsContainer?.nativeElement) {
      observer.observe(this.billDetailsContainer.nativeElement);
    }
  }

  onIntersection(isIntersecting: boolean): void {
    if (this.showViewDetailedButtonContainer !== isIntersecting) {
      this.showViewDetailedButtonContainer = isIntersecting;
    }
  }

  async modifyCartByParams(): Promise<void> {
    const type: string = this.route.snapshot.queryParams?.type || '';
    const productIds: string[] = this.route.snapshot.queryParams?.products?.split(',');
    if (type === 'PRODUCT' && productIds?.length) {
      this.paramsAdd = true;
      const products = JSON.parse(JSON.stringify(await this.conn.findProductsById(productIds)));
      await this.addToCart(productIds, products);
    }

    if (type === 'REGIMEN' && productIds?.length) {
      const filteredProductIds = productIds
        .filter((id: string): boolean => !this.cartItems.find((each: any): any => each.productId === id));
      const products = JSON.parse(JSON.stringify(await this.conn.findProductsById(filteredProductIds)));
      await this.addToCart(filteredProductIds, products);
    }
  }

  async addToCart(productIds: string[], products: any[]): Promise<void> {
    // eslint-disable-next-line no-restricted-syntax
    for (const productId of productIds) {
      const quantityInCart: any = this.cartItems.find((each: any): any => each.productId === productId)?.quantity || 0;
      const limit: any = products.find((each: any): any => each.objectId === productId)?.prescriptionRequired
        ? this.rxLimit : this.nonRxLimit;

      const request = Math.min(limit - quantityInCart, 1)
        ? this.conn.addProductToCart({ productId, quantity: Math.min(limit - quantityInCart, 1) })
        : Promise.resolve();
      // eslint-disable-next-line no-await-in-loop
      await request;
    }

    await this.checkForFreeProduct('add');
  }

  /**
   *
   * @param isAddOrRemove is used to fetch the quantity of product on add or remove of a item
   */

  async getCart(isAddOrRemove: boolean = false): Promise<void> {
    await this.fetchCart();
    this.totalProducts = 0;
    if (this.cartItems.length) {
      this.cartItems.forEach((each: any): void => {
        this.totalProducts += each.quantity;
        this.productsInCart[each.productId] = each.quantity;
        if (isAddOrRemove) {
          // eslint-disable-next-line no-param-reassign
          each.quantityData = this.productsQuantity[each.productId];
        }
      });
    }
    if (!this.cartItems.length) {
      this.noProductsInCart = true;
    } else {
      this.noProductsInCart = false;
    }
    if (this.cartItems.length && this.aovExp) {
      this.setAOVVariables();
      await this.getProductQuantity();
      if (this.amountRemaining > 0) {
        const product = this.cartItems.find((each: any): any => each.price === 0);
        if (product) {
          await this.removeProduct(product, 'DELETE');
          this.showFreeProd = false;
        }
      } else {
        this.amountRemaining = 0;
      }
      const prod = this.cartItems.indexOf(this.cartItems.find((each: any): any => each.price === 0));
      if (prod >= 0) {
        const len = this.cartItems.length;
        const ret = this.cartItems[prod];
        for (let i = prod + 1; i < len; i += 1) {
          this.cartItems[i - 1] = this.cartItems[i];
        }
        this.cartItems.length = len - 1;
        this.cartItems.push(ret);
      }
    }
    if (this.aovExp) await this.checkForFreeProduct('check');
    else {
      const product = this.cartItems.find((each: any): any => each.price === 0);
      if (product && !this.newCartUI) {
        this.removeProduct(product);
      } else {
        this.removeProduct(product, 'DELETE');
      }
    }
    this.removeProductsWithPriceZero();
  }

  setAOVVariables(): void {
    if (this.newCartUI) {
      this.freeProductsArray = this.cartItems.filter((each: any): any => each.price === 0);
      this.amountRemaining = this.maxPrice.totalValue - this.cart.totalPrice;
      this.aovBannerPercentageDone = Math.round((this.cart.totalPrice / this.maxPrice.totalValue) * 100);
    } else {
      this.amountRemaining = this.maxPrice.totalValue - this.cart.get('totalPrice');
    }
  }

  async fetchCart(): Promise<void> {
    if (this.newCartUI) {
      const sessionToken = this.user.get('sessionToken');
      const cartData = await this.conn.serverApi.getCart(this.user.id, sessionToken);
      this.cart = cartData?.data?.data;
      this.cartItems = this?.cart?.lineItems;
    } else {
      this.cart = await this.conn.getCart();
      this.cartItems = this.cart.get('lineItems');
    }
  }

  async removeProductsWithPriceZero(): Promise<any> {
    const product = this.cartItems.find((each: any): any => each.price === 0);
    if (product?.quanity > 1) {
      const params = {
        productId: this.maxPrice.productID,
        quantity: 1,
      };
      await this.conn.removeProductFromCart(params);
      this.getCart();
    }
  }

  async getProductQuantity(): Promise<any> {
    let productsFromParams;
    this.cartItems.forEach(async (element: any): Promise<any> => {
      [productsFromParams] = await this.conn.findProductsById(element.productId);
      if (productsFromParams) {
        // eslint-disable-next-line no-param-reassign
        element.quantityData = `${productsFromParams?.get('quantity')} ${productsFromParams?.get('quantityUnit')}`;
        // eslint-disable-next-line no-param-reassign
        element.isRx = productsFromParams.get('prescriptionRequired');
        // eslint-disable-next-line no-param-reassign
        element.inventoryStatus = productsFromParams.get('inventoryStatus');
        const bgImage = productsFromParams?.get('rebrandedImageWithBackground');
        // eslint-disable-next-line no-param-reassign
        element.rebrandedImage = bgImage ? bgImage[0] : '';
      }
      this.productsQuantity[element.productId] = element.quantityData;
      if (this.discontinuedList?.length) {
        const prod: any = this.discontinuedList?.find((each: any): any => each.productId === element.productId);
        if (!prod && element.inventoryStatus
      && element.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
          this.discontinuedList.push(element);
          this.checkdiscontinuedProduct(true);
        }
      }
    });
  }

  fetchDiscount(): any {
    this.totalMRP = 0;
    this.totalSP = 0;
    this.cartItems.forEach((element: any):any => {
      if (this.productsInCart[element.productId]) {
        this.totalSP += this.productsInCart[element.productId] * element.price;
        this.totalMRP += this.productsInCart[element.productId] * element.mrp;
      }
    });
    this.totalMRP = Math.floor(this.totalMRP);
    this.totalSP = Math.floor(this.totalSP);
    this.cashBalance = this.tempCashBalance;
    if (this.showCashBalance) {
      const cashDiscount = Math.min(this.cashBalance, this.totalSP);
      this.totalSP -= cashDiscount;
      this.cashBalance = cashDiscount;
    }
    let discount = 0;
    this.totalMRP = Math.floor(this.totalMRP);
    if (this.totalMRP > this.totalSP) {
      discount = Math.floor(((this.totalMRP - this.totalSP) * 100) / this.totalMRP);
    }
    this.discount = discount;
  }
  async checkForFreeProduct(value?: any): Promise<void> {
    const product = this.cartItems.find((each: any): any => each.price === 0);
    const freeProduct = this.localStorage.getValue('freeProduct');
    if (product) this.showFreeProd = true;
    if (value === 'check' && !product && !freeProduct) {
      if ((this.newCartUI ? this.cart.totalPrice : this.cart.get('totalPrice')) >= this.maxPrice.totalValue && !product) {
        // This is used to find whether a 0 price product is present in cart or not
        const found = this.cartItems.some((el: any): any => el.productId === this.maxPrice.productID);
        if (!found) {
          const params1 = {
            productId: this.maxPrice.productID,
            quantity: 1,
          };
          await this.conn.addProductToCart(params1);
          this.productsInCart[this.maxPrice.productID] = 1;
          this.getCart();
        }
      }
    }
    if (value === 'check' && product && (this.newCartUI ? this.cart.totalPrice : this.cart.get('totalPrice')) < this.maxPrice.totalValue) {
      const params1 = {
        productId: this.maxPrice.productID,
        quantity: 1,
      };
      this.removeProduct(params1);
      this.showFreeProd = false;
    }
  }

  // eslint-disable-next-line complexity
  async addOrRemove(product: any, action: string, event?: any): Promise<void> {
    event.stopPropagation();
    const quantity = this.productsInCart[product.productId] || 0;
    const [productsFromParams]: any = await this.conn.findProductsById(product.productId);
    if (productsFromParams) {
      // eslint-disable-next-line no-param-reassign
      product.prescriptionRequired = productsFromParams.get('prescriptionRequired');
      // eslint-disable-next-line no-param-reassign
      product.inventoryStatus = productsFromParams.get('inventoryStatus');
      if (action === 'ADD' && !product.prescriptionRequired && quantity === this.nonRxLimit) {
        this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 5 per product' });
        return;
      }
      if (action === 'ADD' && product.prescriptionRequired && quantity === this.rxLimit) {
        this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 3 per product' });
        return;
      }
      if (action === 'ADD' && !product.prescriptionRequired && this.productsInCart[product.productId] < this.nonRxLimit) {
        await this.callAddProductsAPIWhenLessThanNonRxLimit(product, quantity);
      }
      if (action === 'ADD' && product.prescriptionRequired && this.productsInCart[product.productId] < this.rxLimit
      ) await this.callAddProductsAPIWhenLessThanRxLimit(product, quantity);
    }
    this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: this.productsInCart }));
    await this.checkForFreeProduct('add');
  }

  async callAddProductsAPIWhenLessThanNonRxLimit(product: any, quantity: number): Promise<void> {
    this.productsInCart[product.productId] = quantity + 1;
    const res = await this.getAddProductResponse(product);
    if (res) {
      const cartItems = this.newCartUI ? res?.lineItems : res.get('lineItems');
      cartItems.forEach((element: any): any => {
        if (element.productId === product.productId && element.quantity > this.nonRxLimit) {
          this.removeProduct(product);
        }
      });
      this.getCart(true);
      this.fetchDiscount();
    }
  }

  async callAddProductsAPIWhenLessThanRxLimit(product: any, quantity: number): Promise<void> {
    this.productsInCart[product.productId] = quantity + 1;
    let res: any;
    if (this.newCartUI) {
      const params = {
        productId: product.productId,
        quantity: 1,
        section: product.section,
        userId: this.user.id,
      };
      res = await this.conn.serverApi.addProductToCart(params, this.user.get('sessionToken'));
    } else {
      const params = {
        productId: product.productId,
        quantity: 1,
      };
      res = await this.conn.addProductToCart(params);
    }
    if (res) {
      const cartItems = this.newCartUI ? res.lineItems : res.get('lineItems');
      cartItems.forEach((element: any): any => {
        if (element.productId === product.productId && element.quantity > this.rxLimit) {
          this.removeProduct(product);
        }
      });
      this.getCart(true);
      this.fetchDiscount();
    }
  }

  async getAddProductResponse(product: any): Promise<any> {
    if (this.newCartUI) {
      const params = {
        productId: product.productId,
        quantity: 1,
        section: product.section,
      };
      return this.conn.addProductToNewCart(params);
    }
    const params = {
      productId: product.productId,
      quantity: 1,
    };
    return this.conn.addProductToCart(params);
  }

  async removeProduct(product?: any, action?: string, event?: any, params?: CartParams): Promise<void> {
    if (event) event.stopPropagation();
    if (!product) return;
    const params1: CartParams = {
      quantity: 0,
      productId: undefined,
    };
    this.calculateQuantityToRemoveFromCart(product, params1, action);
    await this.callRemoveProductFromCartAPI(params, params1);
    this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: this.productsInCart }));
    await this.getCart(true);
    this.fetchDiscount();
    await this.checkForFreeProduct();
  }

  calculateQuantityToRemoveFromCart(product: any, params1: CartParams, action: string): void {
    const quantity = this.productsInCart[product.productId] || 0;
    // eslint-disable-next-line no-param-reassign
    params1.productId = product.productId;
    if (action === 'REMOVE' && product?.quantity > 0) {
      this.productsInCart[product.id] = quantity - 1;
      // eslint-disable-next-line no-param-reassign
      params1.quantity = this.newCartUI ? 1 : product.quantity - 1;
    }
    if (action === 'DELETE') {
      this.productsInCart[product.id] = quantity - 1;
      // eslint-disable-next-line no-param-reassign
      params1.quantity = product.quantity;
    }
  }

  async callRemoveProductFromCartAPI(params: CartParams, params1: CartParams): Promise<void> {
    const qParams = this.newCartUI ? { ...params || params1, userId: this.user.id } : params || params1;
    if (this.newCartUI) {
      await this.conn.serverApi.removeProductFromCart(qParams, this.user.get('sessionToken'));
    } else {
      await this.conn.removeProductFromCart(qParams);
    }
  }

  viewDetails(): void {
    const queryParams = { showAll: true };
    this.router.navigate(['user/order/reorder'], { queryParams });
  }

  async removeFreeProd(type: string): Promise<void> {
    this.removeFreeGift = !this.removeFreeGift;
    let freeProduct: boolean;
    const params = {
      productId: this.maxPrice.productID,
      quantity: 1,
    };
    const found = this.cartItems.some((el: any): any => el.productId === this.maxPrice.productID);
    switch (type) {
      case 'add':
        if (!found) {
          await this.conn.addProductToCart(params);
          this.localStorage.delete('freeProduct');
          this.showFreeProd = true;
          this.noFreeProduct = false;
        }
        break;
      case 'remove':
        await this.conn.removeProductFromCart(params);
        freeProduct = true;
        this.localStorage.setValue('freeProduct', freeProduct);
        this.showFreeProd = false;
        break;
      default:
        break;
    }
    await this.fetchCart();
    this.getProductQuantity();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
    this.eventLogger.cleverTapEvent('backClicked', JSON.stringify({ name: 'cart' }));
  }

  async checkdiscontinuedProduct(checkAlternate: boolean = false): Promise<void> {
    const oldProduct = this.discontinuedList[this.discontinuedList.length - 1];
    this.oldProduct = await this.conn.findCatalogWithAlternateProduct(oldProduct.productId);
    if (!checkAlternate && this.oldProduct?.get('alternateProduct')) {
      this.newProduct = this.oldProduct?.get('alternateProduct');
      this.isDiscontinued = true;
    } else if (checkAlternate && !this.oldProduct?.get('alternateProduct')) {
      const params1 = {
        productId: this.oldProduct.id,
        quantity: 1,
      };
      await this.conn.removeProductFromCart(params1);
      this.discontinuedList.pop();
      await this.getCart();
    }
  }

  async checkout(): Promise<void> {
    if (this.discontinuedList.length) {
      await this.checkdiscontinuedProduct();
    } else {
      const productList = [];
      this.cartItems.forEach((element: any): any => {
        if (this.aovExp && this.maxPrice.productID === element.productId) {
          if (element.quantity > 1) {
            const params1 = {
              productId: this.maxPrice.productID,
              quantity: 1,
            };
            this.removeProduct(params1);
          }
        }
        for (let index = 0; index < element.quantity; index += 1) {
          if (this.aovExp && productList.includes(this.maxPrice.productID)) {
            return;
          }
          productList.push(element.productId);
        }
      });
      const products = productList.toString();
      const url = 'user/checkout';
      const queryParams = { type: this.appConfig.Shared.Order.Type.PRODUCT, products };
      this.router.navigate([url], { queryParams });
      this.eventLogger.cleverTapEvent('click-checkout', JSON.stringify({ pageName: 'cart' }));
    }
  }

  goToList(): void {
    const queryParams = { showAll: true };
    this.router.navigate(['user/order/reorder'], { queryParams });
  }

  async closePopup(isReplace: boolean = false): Promise<void> {
    this.popUp = { open: false };
    this.isDiscontinued = false;
    this.broadcast.broadcast('NAVIGATION_BACK');
    if (isReplace) {
      this.discontinuedList.pop();
      await this.getCart(true);
      this.getProductQuantity();
      this.fetchDiscount();
    }
  }

  async changeProduct(openArticle: boolean = false): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'discontinued-product-changed' }));
    try {
      await this.conn.replaceDiscontinuedProduct(this.oldProduct.id, this.newProduct.id);
      this.replaceDiscontinuedProduct(openArticle);
    } catch (err) {
      const msg = JSON.parse(JSON.stringify(err));
      if (msg.message === 'A duplicate value for a field with unique values was provided') {
        this.replaceDiscontinuedProduct(openArticle);
      }
    }
  }

  async replaceDiscontinuedProduct(openArticle: boolean): Promise<any> {
    const params = {
      productId: this.newProduct.id,
      quantity: 1,
    };
    const quanity = this.productsInCart[this.oldProduct.id];
    for (let index = 0; index < quanity; index += 1) {
      // eslint-disable-next-line no-await-in-loop
      await this.conn.removeProductFromCart({ productId: this.oldProduct.id, quantity: 1 });
    }
    await this.conn.addProductToCart(params);
    if (!openArticle) {
      this.closePopup(true);
    }
  }

  async openArticle(): Promise<any> {
    await this.changeProduct(true);
    this.location.replaceState('cart');
    this.popUp = { open: false };
    this.isDiscontinued = false;
    this.viewProduct(this.newProduct);
  }

  viewProduct(product: any): void {
    const productData = JSON.parse(JSON.stringify(product));
    this.conn.navigateToURL(`/product/${productData.objectId}`);
  }

  goToShop(): any {
    this.router.navigate(['/user'], { queryParams: { tab: 'shop', back: 'home' } });
  }
  viewProductDetail(product: any): void {
    this.conn.navigateToURL(`/product/${product.productId}`);
  }

  async addSingleProductToCart(productId: string): Promise<void> {
    const payload = {
      productId,
      quantity: 1,
      section: 'productsYouMayNeed',
    };
    await this.conn.addProductToNewCart(payload);
    await this.getCart();
    await this.getProductQuantity();
    await this.fetchDiscount();
  }

  scrollToBottom(): void {
    const scrollView = this.windowRef.nativeWindow.document.getElementById('newCartUIContainer');
    setTimeout((): void => {
      if (scrollView.scroll instanceof Function) {
        scrollView.scroll(0, scrollView.scrollHeight);
      }
    }, 0);
  }
}
