<div class="popup-bg" *ngIf="show" [ngClass]="{ 'tw-w-[100vw] tw-h-[100vh]': appConfig.Shared.String.ORDER_SELECTION }">
  <div class="container anim-popup tw-font-body" (click)="stopEvent($event)" [ngClass]="{ 'tw-w-[90%] tw-h-[90%]': appConfig.Shared.String.ORDER_SELECTION }" >
  <div class="mB15 bold fontL3" *ngIf="title">
    <translate-string [key]="title"></translate-string>
  </div>
  <div class="mB15 text-center" *ngIf="titleIcon">
    <em [class]="titleIcon"></em>
  </div>
  <div class="message" *ngIf="message.text" [innerHTML]="message.text"></div>
  <div class="message" *ngIf="message.type" [ngSwitch]="message.type">
    <div *ngSwitchCase="appConfig.Shared.String.UPDATE_APP_DETAIL" i18n="@@yourCurrentAppVersionNotSupported">
    Your current app version is not supported. Kindly upgrade to continue
    using our service.
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.UPDATE_BROWSER_DETAIL" i18n="@@youNeedTheLatestVersion">
    You need the latest version of following apps to continue
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.INSTANT_CHECKUP_RATING" i18n="@@instantCheckupFeedback">
    Instant Skin Analysis are free to help users evaluate their skin. Let us
    know how your experience was.
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.OFFLINE" i18n="@@youAreOffline">
    You are offline. Kindly check the internet connectivity.
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.CANCEL_ORDER_OFFER_CONFIRM">
    <div *ngIf="user?.get('orderState') !== appConfig.Shared.User.OrderState.DELIVERED" i18n="@@cancelOrderConfirmationTreatment">
      Call us between 10 am to 7 pm for any questions about your treatment
      kit
    </div>
    <div class="pT15 bold" *ngIf="user?.get('orderState') !== appConfig.Shared.User.OrderState.DELIVERED">
      <em class="cs-icon-phone-in-talk fontL3 pR10"></em><a class="clr-link fontL1" href="tel:080-4680-9361">080-4680-9361</a>
    </div>
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.ORDER_CANCEL_REASON" i18n="@@orderCancelReason">
    Order is cancelled. Please let us know the reason.
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.LOGOUT_CONFIRM" i18n="@@logoutConfirmation">
    Are you sure you want to log out?
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.RATE_US" i18n="@@thanksRateUs">
    Thanks for the feedback. Please rate us 5 star.
    </div>
    <div *ngSwitchCase="appConfig.Shared.String.PINCODE" i18n="@@invalidPincode">
    Pincode looks wrong, Do you want to autofill the correct pincode?
    </div>
    <div class="fontL2 bold" *ngSwitchCase="appConfig.Shared.String.ORDER_SELECTION" i18n="@@chooseOrder">
    Choose Order
    </div>
    <div class="fontL2 bold" *ngSwitchCase="appConfig.Shared.String.PRODUCT_SELECTION">
    Choose Product
    </div>
  </div>
  <div *ngIf="appConfig.Dialog.MULTI_OPTION_SINGLE_SELECT === type" [ngClass]="{ 'tw-h-[85%] tw-overflow-auto': appConfig.Shared.String.ORDER_SELECTION }">
    <div class="mB15" *ngFor="let option of options;let i=index;">
    <radio-btn [checked]="option.selected" (checkedChange)="optionSingleSelectionChange(i)">
      <div>
      <div class="fontR bold">{{option.text}}</div>
      <div class="fontS1">{{option.subText}}</div>
      </div>
    </radio-btn>
    </div>
  </div>
  <div class="confirmation" *ngIf="appConfig.Dialog.MULTI_OPTION_SINGLE_SELECT === type" [ngClass]="{ 'tw-pt-2 tw-sticky tw-bottom-0': appConfig.Shared.String.ORDER_SELECTION }">
    <div class="w50">
    <cs-button (callback)="onClickNo()" [class]="'secondary only-text pL0 btn-xs'"><cs-button-text><translate-string [key]="cancelText"></translate-string></cs-button-text></cs-button>
    </div>
    <div class="w50">
    <cs-button (callback)="onOptionConfirm()" [class]="'primary w100 btn-xs'"><cs-button-text><translate-string [key]="okText"></translate-string></cs-button-text></cs-button>
    </div>
  </div>
  <div class="flex justify-end" *ngIf="appConfig.Dialog.ALERT === type">
    <cs-button (callback)="onClickNo()" [class]="'pLR15 primary bold ls-05 only-text'"><cs-button-text><translate-string [key]="cancelText"></translate-string></cs-button-text></cs-button>
  </div>
  <div class="confirmation" *ngIf="appConfig.Dialog.CONFIRMATION === type" [ngClass]="{ 'tw-pt-2 tw-sticky tw-bottom-0': appConfig.Shared.String.ORDER_SELECTION }">
    <div class="w50">
    <cs-button (callback)="onClickYes()" [class]="'secondary only-text pL0 btn-xs'"><cs-button-text><translate-string [key]="okText"></translate-string></cs-button-text></cs-button>
    </div>
    <div class="w50">
    <cs-button (callback)="onClickNo()" [class]="'primary w100 btn-xs'"><cs-button-text><translate-string [key]="cancelText"></translate-string></cs-button-text></cs-button>
    </div>
  </div>
  <div class="flex justify-evenly" *ngIf="appConfig.Dialog.MULTI_BUTTON === type">
    <div class="w100" *ngFor="let input of inputs; let i= index" [ngClass]='{ "mB15": i !== inputs.length - 1}'>
    <cs-button class="w100" [class]="'w100 primary'" (callback)="selectedMultiInput(input.text)"><cs-button-text><translate-string [key]="input.text"></translate-string></cs-button-text></cs-button>
    </div>
  </div>
  <div *ngIf="appConfig.Dialog.RADIO_BUTTON === type">
    <div class="w100" *ngFor="let input of inputs;">
    <cs-button [class]="'w100 primary only-text'" (callback)="selectedRadioInput(input.text)"><cs-button-text><translate-string [key]="input.text"></translate-string></cs-button-text></cs-button>
    </div>
  </div>
  </div>
</div>