import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ParseKeys } from 'api-client/src/common';
import { AppConfig } from '../../app.config';

enum OrderTypeEnum {
  'completed',
  'active',
  'cancelled',
}

@Component({ selector: 'user-order-list', templateUrl: './user-order-list.html' })
export class UserOrderListComponent {
  orderType: typeof OrderTypeEnum = OrderTypeEnum;
  activeOrderType: OrderTypeEnum = OrderTypeEnum.active;
  user: any;
  orders: any[];
  isLoading: boolean = true;
  products: any = {};

  orderPaymentPendingStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPaymentPendingStages];
  orderPlacedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPlacedStages];
  orderPackedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPackedStages];
  orderDispatchedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDispatchedStages];
  orderDeliveryFailedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveryFailedStages];
  orderOutForDeliveryStages: any[] = [...this.appConfig.Shared.Order.Stage.orderOutForDeliveryStages];
  orderDeliveredStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveredStages];
  orderCanceledStages: any[] = [...this.appConfig.Shared.Order.Stage.orderCanceledStages];

  expectedDeliveryDateStages: any[] = [
    ...this.orderPlacedStages,
    ...this.orderPackedStages,
    ...this.orderDispatchedStages,
    ...this.orderDeliveryFailedStages,
    ...this.orderOutForDeliveryStages,
  ];

  orderPayments: Table.Payment[] = [];
  totalTransactionAmount: number = 0;

  constructor(
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    private appConfig: AppConfig,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();
    this.activeOrderType = +(this.route.snapshot.queryParams?.tab || OrderTypeEnum.active);
    this.loadOrders();
  }

  changeOrderType(orderType: OrderTypeEnum): void {
    this.activeOrderType = orderType;
    this.router.navigate([], { queryParams: { tab: orderType }, replaceUrl: true, relativeTo: this.route });
    this.loadOrders();
  }

  private async loadOrders(): Promise<void> {
    this.isLoading = true;
    const where: any = { user: this.user };
    const select: Array<ParseKeys<Table.Order>> = ['orderNumber', 'type', 'amount', 'stage', 'regimen',
      'productInfo', 'paymentType', 'deliverOnDate', 'deliveredOn', 'canceledByUser', 'serviceInfo'];
    const inactiveOrderStage: any[] = [
      this.appConfig.Shared.Order.Stage.CANCELED,
      this.appConfig.Shared.Order.Stage.RETURNED,
      this.appConfig.Shared.Order.Stage.REFUND_PROCESSED,
      this.appConfig.Shared.Order.Stage.CANCEL_REQUESTED,
      this.appConfig.Shared.Order.Stage.LOST_IN_TRANSIT,
      this.appConfig.Shared.Order.Stage.NONSERVICEABLEAREA,
      this.appConfig.Shared.Order.Stage.COURIER_NOT_AVAILABLE,
      this.appConfig.Shared.Order.Stage.RETURN_INITIATED,
      this.appConfig.Shared.Order.Stage.RETURN_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_INITIATED,
      this.appConfig.Shared.Order.Stage.BOT_CANCELED,
      this.appConfig.Shared.Order.Stage.COMPLETED,
      this.appConfig.Shared.Order.Stage.DELIVERED,
    ];
    const completedStages: any[] = [
      this.appConfig.Shared.Order.Stage.COMPLETED,
      this.appConfig.Shared.Order.Stage.DELIVERED,
    ];
    const cancelledStages: any[] = [
      this.appConfig.Shared.Order.Stage.CANCELED,
      this.appConfig.Shared.Order.Stage.RETURNED,
      this.appConfig.Shared.Order.Stage.REFUND_PROCESSED,
      this.appConfig.Shared.Order.Stage.CANCEL_REQUESTED,
      this.appConfig.Shared.Order.Stage.LOST_IN_TRANSIT,
      this.appConfig.Shared.Order.Stage.NONSERVICEABLEAREA,
      this.appConfig.Shared.Order.Stage.COURIER_NOT_AVAILABLE,
      this.appConfig.Shared.Order.Stage.RETURN_INITIATED,
      this.appConfig.Shared.Order.Stage.RETURN_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_INITIATED,
      this.appConfig.Shared.Order.Stage.BOT_CANCELED,
    ];
    switch (this.activeOrderType) {
      case OrderTypeEnum.active: {
        where.stage = { $nin: inactiveOrderStage };
        break;
      }
      case OrderTypeEnum.completed: {
        where.stage = completedStages;
        break;
      }
      case OrderTypeEnum.cancelled: {
        where.stage = cancelledStages;
        break;
      }
      default: where.stage = { $nin: completedStages };
    }
    try {
      const orders = await this.conn.fetchOrders(where, select);
      // Fetch all products in all orders for once
      let productIds = orders.map((order: any): any => order.get('productInfo').map((product: any): any => product.id)).flat();
      productIds = Array.from(new Set(productIds));
      const products = await this.conn.findProducts({ objectId: { $in: productIds } });
      this.products = products.reduce((object: any, product: any): any => {
        // eslint-disable-next-line no-param-reassign
        object[product.id] = product;
        return object;
      }, {});

      orders.forEach(async (order: Table.Order): Promise<void> => {
        this.orderPayments = await this.conn.findSuccessfulOrderPayments(order);
        await this.updateOrderAmountForCOD(order);
        order.get('productInfo').forEach((product: any): any => {
          // eslint-disable-next-line no-param-reassign
          product.image = this.getProductImage(product.id) || product.image;
        });
      });

      this.orders = orders?.filter((order: any): any => (order.get('stage') !== this.appConfig.Shared.Order.Stage.INITIAL
        && order.get('type') !== 'PRODUCT') || order.get('stage') !== this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.eventLogger.logError(error);
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  async updateOrderAmountForCOD(order: Table.Order): Promise<void> {
    this.totalTransactionAmount = this.orderPayments.reduce((total: number, payment: Table.Payment): number => {
      const transactionAmount = Number(payment?.get('TransactionAmount') || 0) / 100;
      return total + transactionAmount;
    }, 0);
    if (order?.get('paymentType') === this.appConfig.Shared.Order.PaymentType.COD) {
      order?.set('amount', order.get('amount') - this.totalTransactionAmount);
    }
  }

  getProductImage(id: any): string {
    const productObj = this.products[id];
    if (!productObj) return '';

    const product = JSON.parse(JSON.stringify(productObj));
    let image = '';
    if (product?.rebrandedImageWithBackground?.length) {
      image = product.rebrandedImageWithBackground[product.rebrandedImageWithBackground.length - 1];
    } else if (product?.image) {
      // eslint-disable-next-line prefer-destructuring
      image = product.image;
    } else if (product?.rebrandedImageWithoutBackground?.length) {
      image = product.rebrandedImageWithoutBackground[product.rebrandedImageWithoutBackground.length - 1];
    }
    return image;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
